import type { Filter } from "@/components/Filters";

import dayjs from "dayjs";
import { memo, useState } from "react";

import Banner from "@/components/Banner";
import DateRangePicker from "@/components/DateRangePicker";
import { PortalContext } from "@/components/Portal";
import SidePanel from "@/components/SidePanel";

import { doDatesSpanAtLeastXMonths } from "@/js/utils/monthDifference";
import { unitFormatter } from "@/js/utils/unitFormatter";

import { BenchmarkingData } from "@/Api/generated";
import { useAppContext } from "@/layouts/AppLayout/AppContext";
import AnnualizedDateDropdown from "@/pages/DashboardPage/DashboardContent/AnnualizedDateDropdown";
import {
  co2eEmissionsDownloadAction,
  co2eEmissionsIntensityDownloadAction,
} from "@/pages/DashboardPage/DashboardContent/CarbonEmissionsCard";
import { energyUsageDownloadAction } from "@/pages/DashboardPage/DashboardContent/EnergyUsageCard";
import { useDashboardResources } from "@/pages/DashboardPage/DashboardContent/utils";

import IndustryBenchmarkCard from "./IndustryBenchmarkCard";
import NotEnoughDataCard, { NotEnoughDataMessageTypes } from "./NotEnoughDataCard";
import SpotTrendCard from "./SpotTrendCard";
import TimeSeriesCard from "./TimeSeriesCard";

export type BenchmarksDrillDownInfoOption =
  | "energy_usage_intensity"
  | "carbon_emissions"
  | "carbon_emission_intensity";

export type BenchmarksDrillDownInfo = {
  title: string;
  spotTrendDescriptionNameText: string;
  spotTrendUnitLabel: string;
  aboutCardContent: any;
  type: BenchmarksDrillDownInfoOption;
  benchmarkName: string;
  numberOfDecimalPlaces: number;
  timeSeriesDownloadAction: (...args: any[]) => void;
};

const PRINCIPAL_EDENRED_SPACE_TOKEN = "s_YSgdsz6XgD8gxyYeQtvj7D";
const PRINCIPAL_STEIGEREILAND_SPACE_TOKEN = "s_3chi8jUWZaLnpADLiXPyG6";

export const BenchmarksDrillDownDrawer = memo(
  ({
    isAggregate = false,
    initialDateRange,
    filters,
    selectedBenchmarkDrilldownOption,
  }: {
    isAggregate: boolean;
    initialDateRange: [string, string];
    filters?: Filter[];
    selectedBenchmarkDrilldownOption: BenchmarksDrillDownInfoOption;
  }) => {
    const { organizationName, featureConfigurations } = useAppContext();
    // used so that the displayed date range can still update when called from openPanel
    const [dateRange, setDateRange] = useState(initialDateRange);
    const { propertyModel } = useDashboardResources(["property"]);
    // this won't exist if there is no space_token query param
    const property = propertyModel?.toJSON();
    const dateGranularity =
      featureConfigurations.TEMP_MONTHLY_DATE_PICKER_ENABLED ? "month" : "day";

    const { energySourceModel, energyConsumptionModel, isLoading } = useDashboardResources(
      selectedBenchmarkDrilldownOption === "energy_usage_intensity" ?
        ["energyConsumption"]
      : ["energySource"],
      {
        dateRange,
        filters,
        spaceId: property?.space_id,
      },
    );
    const energyUnit = unitFormatter(energyConsumptionModel?.get("energy_unit"));
    const benchmarks =
      energyConsumptionModel?.get("benchmarks") || energySourceModel?.get("benchmarks") || [];

    const BenchmarksDrillDownInfoOptions: BenchmarksDrillDownInfo[] = [
      {
        title: "Energy Usage Intensity",
        timeSeriesDownloadAction: (data) =>
          energyUsageDownloadAction(data, property, organizationName, energyUnit),

        aboutCardContent: () => {
          return (
            <>
              <p>
                Think of energy usage intensity (EUI) as an ‘energy efficiency score’ for buildings.
                EUI takes into account all the energy a building uses (electricity, natural gas, and
                more), and then divides it by the building’s size (usually measured in square feet).
                This gives us a number that shows how much energy is used per unit of space.
              </p>

              <p>
                So, a low EUI is like having a super fuel-efficient car. It means the building is
                doing a great job of using energy wisely, which is not only good for the environment
                but also helps save you money on energy bills. On the other hand, a high EUI is like
                a gas-guzzler, indicating that the building might need some energy-saving
                improvements.
              </p>

              <h6>Cambio Methodology Notes</h6>
              <p>
                The industry standard is to quote annualized EUI for a property in thousands of
                British thermal units (kBtu) per square foot. Cambio calculates annualized EUI per
                month then provides a simple average over a given period. We then compare your
                building’s EUI to the benchmark average of your building’s asset class, per the
                EPA’s EnergyStar data.
              </p>
            </>
          );
        },
        type: "energy_usage_intensity",
        spotTrendUnitLabel: energyUnit + " psf",
        spotTrendDescriptionNameText: "energy usage intensity",
        benchmarkName: "avg_eui",
        numberOfDecimalPlaces: 1,
      },

      {
        title: "Carbon Emissions",
        timeSeriesDownloadAction: (data) =>
          co2eEmissionsDownloadAction(data, property, organizationName),
        aboutCardContent: () => {
          return (
            <p>
              Carbon emissions are the ‘footprint’ your building leaves on the environment. When we
              use energy sources like electricity and natural gas to power a building, they release
              carbon emissions into the atmosphere. The world is working towards net zero emissions
              to combat climate change, and buildings make up 40% of the world’s carbon emissions.
              Cambio’s mission is to help you understand your carbon footprint and achieve net zero!
            </p>
          );
        },
        type: "carbon_emissions",
        spotTrendUnitLabel: "metric tons",
        benchmarkName: "total_carbon",
        numberOfDecimalPlaces: 1,
        spotTrendDescriptionNameText: "carbon emissions",
      },
      {
        title: "Carbon Emissions Intensity",
        timeSeriesDownloadAction: (data) =>
          co2eEmissionsIntensityDownloadAction(data, property, organizationName),
        aboutCardContent: () => {
          return (
            <p>
              Carbon emissions intensity is like a ‘sustainability rating’ on your building’s energy
              use and therefore how much carbon pollution it’s creating. The metric takes the total
              carbon emissions a building emits across all energy sources a building uses
              (electricity, natural gas and more), and then divides it by the building’s size
              (usually measured in square feet). This gives us a number that shows how much carbon
              is emitted per unit of space and helps us compare the ‘sustainability rating’ across
              multiple properties.
            </p>
          );
        },
        spotTrendUnitLabel: "kg CO2e psf",
        type: "carbon_emission_intensity",
        benchmarkName: "carbon_emission_intensity",
        numberOfDecimalPlaces: 1,
        spotTrendDescriptionNameText: "carbon emissions intensity",
      },
    ];

    const getHardcodedBenchmarkNumber = (benchmarkToken: string) => {
      switch (benchmarkToken) {
        case PRINCIPAL_EDENRED_SPACE_TOKEN:
          return 74.68155882;
        case PRINCIPAL_STEIGEREILAND_SPACE_TOKEN:
          return 84.2897682;
      }
    };

    const benchmarksDrillDownInfoOption = BenchmarksDrillDownInfoOptions.find(
      (option) => option.type === selectedBenchmarkDrilldownOption,
    );

    const benchmarkName = benchmarksDrillDownInfoOption?.benchmarkName;

    let benchmarkInfo = benchmarks?.find(
      (benchmark: BenchmarkingData) => benchmark.metric_name === benchmarkName,
    );

    const doesDateSpan3Months = doDatesSpanAtLeastXMonths(dateRange[0], dateRange[1], 3);

    return (
      <SidePanel
        className="BenchmarksDrillDownDrawer"
        size="medium"
        title={
          <>
            <h2>
              {benchmarksDrillDownInfoOption?.title ? benchmarksDrillDownInfoOption?.title : ""}
            </h2>
            <PortalContext.Provider value={{ parent: { current: document.body } }}>
              <div>
                {featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ?
                  <AnnualizedDateDropdown
                    dateRange={dateRange}
                    onChangeMetricDateRange={(range: [string, string]) => {
                      setDateRange([range[0], range[1]]);
                    }}
                  />
                : <DateRangePicker
                    dateRange={dateRange}
                    onChangeDateRange={(range: [string, string]) => {
                      setDateRange([range[0], range[1]]);
                    }}
                    granularity={dateGranularity}
                  />
                }

                {/* Intentionally blank div  */}
                <div></div>
              </div>
            </PortalContext.Provider>
          </>
        }
      >
        <div>
          {(
            !doesDateSpan3Months ||
            (!benchmarkInfo?.spot_trend?.current_value &&
              benchmarkInfo?.spot_trend?.current_value !== 0)
          ) ?
            <NotEnoughDataCard
              timePeriodTooSmall={
                !doesDateSpan3Months ?
                  NotEnoughDataMessageTypes.SELECT_MORE_TIME
                : NotEnoughDataMessageTypes.NOT_ENOUGH_DATA
              }
            />
          : null}

          {(
            doesDateSpan3Months &&
            (benchmarkInfo?.spot_trend?.current_value ||
              benchmarkInfo?.spot_trend?.current_value === 0)
          ) ?
            <div>
              {(
                !featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED &&
                !(
                  featureConfigurations.TEMP_BENCHMARK_YOY &&
                  //checks whether the date range is the whole year. In that case, if the feature flag is true,  we don't show this card and only show the year over year card
                  dayjs(dateRange[0]).isSame(dayjs(dateRange[0]).startOf("year"), "date") &&
                  dayjs(dateRange[1]).isSame(dayjs(dateRange[0]).endOf("year"), "date")
                )
              ) ?
                <SpotTrendCard
                  unitLabel={benchmarksDrillDownInfoOption?.spotTrendUnitLabel}
                  currentValue={benchmarkInfo?.spot_trend?.current_value}
                  priorValue={benchmarkInfo.spot_trend?.previous_value}
                  changePercentage={benchmarkInfo?.spot_trend?.change_percentage}
                  isAggregate={isAggregate}
                  descriptionName={benchmarksDrillDownInfoOption?.spotTrendDescriptionNameText}
                  dateRange={dateRange}
                  dateGranularity={dateGranularity}
                  isYearOverYear={false}
                  isLoading={isLoading}
                  maximumFractionDigits={benchmarksDrillDownInfoOption?.numberOfDecimalPlaces}
                />
              : null}

              {(
                featureConfigurations.TEMP_BENCHMARK_YOY &&
                dayjs(dateRange[1]).diff(dayjs(dateRange[0]), "year") < 1
              ) ?
                <SpotTrendCard
                  unitLabel={benchmarksDrillDownInfoOption?.spotTrendUnitLabel}
                  currentValue={benchmarkInfo?.spot_trend?.current_value}
                  priorValue={benchmarkInfo?.spot_trend?.previous_period_yoy_value}
                  maximumFractionDigits={benchmarksDrillDownInfoOption?.numberOfDecimalPlaces}
                  changePercentage={benchmarkInfo?.spot_trend?.yoy_change_percentage}
                  isAggregate={isAggregate}
                  descriptionName={benchmarksDrillDownInfoOption?.spotTrendDescriptionNameText}
                  dateRange={dateRange}
                  dateGranularity={dateGranularity}
                  isYearOverYear={true}
                  isLoading={isLoading}
                />
              : null}

              {(
                property &&
                // the industry benchmark card is only supported for US properties and in two principal properties where values are hardcoded
                (property.address__country === "USA" ||
                  (benchmarkName === "avg_eui" &&
                    (property.token === PRINCIPAL_EDENRED_SPACE_TOKEN ||
                      property.token === PRINCIPAL_STEIGEREILAND_SPACE_TOKEN))) &&
                benchmarkInfo?.spot_trend?.current_value &&
                selectedBenchmarkDrilldownOption === "energy_usage_intensity"
              ) ?
                <IndustryBenchmarkCard
                  currentValue={parseFloat(benchmarkInfo?.industry_benchmark?.current_value)
                    .toFixed(benchmarksDrillDownInfoOption?.numberOfDecimalPlaces)
                    .toLocaleString()}
                  assetName={
                    property?.asset_class?.primary_function ?
                      property?.asset_class?.primary_function
                    : ""
                  }
                  assetClass={
                    property?.asset_class?.display_text ?
                      property?.asset_class?.display_text.toLowerCase()
                    : ""
                  }
                  unitLabel={benchmarksDrillDownInfoOption?.spotTrendUnitLabel}
                  benchmarkValue={parseFloat(
                    (
                      benchmarkName === "avg_eui" &&
                        (property?.token === PRINCIPAL_EDENRED_SPACE_TOKEN ||
                          property.token === PRINCIPAL_STEIGEREILAND_SPACE_TOKEN)
                    ) ?
                      getHardcodedBenchmarkNumber(property?.token)
                    : benchmarkInfo?.industry_benchmark?.previous_value,
                  )
                    .toFixed(benchmarksDrillDownInfoOption?.numberOfDecimalPlaces)
                    .toLocaleString()}
                  propertyName={property?.name}
                  changePercentage={
                    (
                      benchmarkName === "avg_eui" &&
                      (property?.token === PRINCIPAL_EDENRED_SPACE_TOKEN ||
                        property.token === PRINCIPAL_STEIGEREILAND_SPACE_TOKEN)
                    ) ?
                      ((benchmarkInfo?.industry_benchmark?.current_value -
                        getHardcodedBenchmarkNumber(property.token)) /
                        getHardcodedBenchmarkNumber(property.token)) *
                      100
                    : benchmarkInfo?.industry_benchmark?.change_percentage
                  }
                  sourceName={
                    property?.token === PRINCIPAL_EDENRED_SPACE_TOKEN ? "PCAF Italy"
                    : property?.token === PRINCIPAL_STEIGEREILAND_SPACE_TOKEN ?
                      "PCAF Netherlands"
                    : undefined
                  }
                  isLoading={isLoading}
                />
              : null}

              <TimeSeriesCard
                downloadAction={benchmarksDrillDownInfoOption?.timeSeriesDownloadAction}
                dateRange={dateRange}
                filters={filters}
                spaceId={property?.space_id}
                type={benchmarksDrillDownInfoOption?.type}
              />
            </div>
          : null}
          <Banner title="About">{benchmarksDrillDownInfoOption?.aboutCardContent()}</Banner>
        </div>
      </SidePanel>
    );
  },
);
