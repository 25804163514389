import type { CalendarProps, DateRange, ValidRange } from "@/components/Calendar";
import type { HovercardToggleHandle } from "@/components/HovercardToggle";

import dayjs, { Dayjs } from "dayjs";
import CustomFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import { useRef, useState } from "react";

import Button from "@/components/Button";
import Calendar from "@/components/Calendar";
import Form, { Input, SubmitButton } from "@/components/Form";
import HovercardToggle from "@/components/HovercardToggle";
import Select from "@/components/Select";

import { classnames } from "@/js/utils/cambio";
import { capitalizeWords } from "@/js/utils/stringFormatter";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getPresetOptions, Presets } from "./constants";

dayjs.extend(utc);
dayjs.extend(CustomFormat);

// without these hints, dayjs won't parse month-year combos with no date
// and these won't work without the CustomFormat plugin
const ParseHelpers: Partial<Record<PickerProps["granularity"], string[]>> = Object.freeze({
  month: ["M-YYYY", "MM-YYYY", "MMM-YYYY", "MMMM-YYYY"],
});

export interface PickerProps {
  /** A tuple denoting the limits of the pickable range. If an entry is null, then the range has no limit */
  validRange?: ValidRange;
  /** This is a range tuple. it can be a string of anything that dayjs accepts */
  dateRange: [Dayjs | string, Dayjs | string];
  disabled?: boolean;
  granularity?: CalendarProps["granularity"];
  /** Callback to changing the date range. The strings are ISO date strings */
  onChangeDateRange: (range: [string, string]) => void;
}

// takes a date range and returns the preset that matches it.
export function getPresetFromDateRange(
  dateRange: [Dayjs | string, Dayjs | string],
  granularity: PickerProps["granularity"],
): Presets {
  return (
    getPresetOptions(granularity)
      .flatMap(({ items }) => items)
      .find(({ range }) => isSameRange(range, dateRange.map(dayjs) as DateRange))?.value ??
    Presets.CUSTOM
  );
}

/**
 * This component basically contains two hovercards: one is a list of preset time range options
 * like "last 12 months," and the other is a calendar and input fields with which to pick a
 * custom range. It is a tricky one because it is full of de-normalized state. Often for reusable
 * components we want to pass in its data as props, and call a passed-in callback when those props
 * change, keeping the component "dumb." But here we have a couple UX considerations:
 *
 * * We don't want to enact any changes until the hovercard containing the calendar closes. This
 *    means all calendar-related information must be held as state, yet still kept in sync when
 *    external data changes.
 * * In the preset dropdown, we enact changes immediately and don't need to keep separate
 *    state, with one exception: the "Custom range" option. When the user clicks that, we have to
 *    pretend that the input to the dropdown is no longer "last 12 months" or whatever it was before
 *    and is instead a custom range that hasn't even been chosen yet. So we have this
 *    "customOverride" state for this purpose, which gets removed when the calendar hovercard closes.
 *
 * So that means we not only have a date range state kept separately from the `dateRange` prop, a
 * `customOverride` state to override what our props would normally want us to render in the preset
 * dropdown, but also we need state for our input fields. This state tracks user typing as normal;
 * when a date is valid, we update the date range state accordingly. When it's not valid, we wait,
 * and on blur we revert the input back to the date range props.
 */
export default function DateRangePicker({
  dateRange,
  onChangeDateRange,
  granularity = "day",
  disabled,
  ...props
}: PickerProps) {
  const isMonthly = granularity === "month";
  const PresetOptions = getPresetOptions(granularity);
  const formatDate = generateFormatDate(granularity);

  // TODO: I don't really feel like this should be in here...
  const { featureConfigurations } = useAppContext();

  /**
   * Denormalized range state based on the dateRange prop that allows us to keep track of user
   * changes in this component without broadcasting them to the rest of the app. Note that while
   * the dateRange prop can be a tuple of strings, we deal with rangeState here as Dayjs objects.
   */
  const [rangeState, setRangeState] = useState<DateRange>(dateRange.map(dayjs) as DateRange);
  /** State that tracks the two input fields where users can manually enter in dates */
  const [[startInputText = "", endInputText = ""], setInputTexts] = useState<string[]>(
    rangeState.map(formatDate),
  );
  /**
   * This is the hack for showing "custom range" in the preset dropdown before a custom range is
   * actually chosen.
   */
  const [customOverride, setCustomOverride] = useState(false);

  // dynamic default valid range. orgs with this feature flag get a custom valid range.
  // I don't love that this is in the reusable component, but it's kinda bulky to add to each
  // DateRangePicker instance
  // TODO: I wonder if this flag would be useful to have for most orgs...
  const validRange =
    props.validRange ||
    ([
      featureConfigurations.ORG_LEVEL_DATA_WINDOW_STARTS_AT_CUTOFF ||
        dayjs().subtract(100, "years"),
      dayjs().startOf("day"),
    ] as ValidRange);

  // handle for the hovercard so that we can open it imperatively when "custom range"
  // is chosen from the dropdown
  const hovercardRef = useRef<HovercardToggleHandle>();

  // from our dateRange prop, find a match in our presets, or else it's a custom range.
  // if the customOverride flag is on, we say its a custom range even when our dateRange
  // prop would say otherwise (this will get reverted when the hovercard gets closed. either
  // the user made a change and we can say "custom" here without the override, or the user
  // did not make a change and we'll go back to showing whatever the dateRange prop dictates.
  const selectedPreset =
    customOverride ?
      Presets.CUSTOM
    : getPresetFromDateRange(dateRange.map(dayjs) as [Dayjs | string, Dayjs | string], granularity);

  /**
   * Sets all our state at once, which is denormalized from our single-source-of-truth data-state.
   * This is called at several points to attempt to keep them in sync where we can (which should be
   * everywhere except where we edit our UI state for this component).
   */
  const resetState = (range: DateRange) => {
    setRangeState(range);
    setInputTexts(range.map(formatDate));
  };

  // make sure inner state stays in sync with new changes
  const onChangeRange = (range: DateRange, preset?: Presets) => {
    if (range.filter(Boolean).length === 2) {
      // only call the callback if the dates have changed, otherwise this can have a pretty big
      // rendering impact on our dashboard pages
      if (!isSameRange(dateRange.map(dayjs) as DateRange, range)) {
        onChangeDateRange(
          range.map((day, i) => (i && isMonthly ? day.endOf("month") : day).toISOString()) as [
            string,
            string,
          ],
        );
      }

      // this will usually already be up-to-date, unless being called from the preset dropdown
      resetState(range);
    } else {
      // revert to last set if we close without a valid range
      resetState(dateRange.map(dayjs) as DateRange);
    }
  };

  return (
    <div className={classnames("DateRangePicker", { disabled })}>
      <Select
        alignment="left"
        disabled={disabled}
        onSelect={(option: Presets) => {
          if (option !== Presets.CUSTOM) {
            onChangeRange(
              PresetOptions.flatMap(({ items }) => items).find(({ value }) => value === option)
                .range as DateRange,
              option,
            );
          } else {
            setCustomOverride(true);
            hovercardRef.current?.open();
          }
        }}
        optionGroups={PresetOptions}
        size="small"
        hovercardClassName="date-range-preset-hovercard"
        value={selectedPreset}
      />
      <HovercardToggle
        ref={hovercardRef}
        alignment="right"
        contents={() => (
          <div className="date-picker-hovercard">
            {/**
             * No need for a submit callback here because the click handler will trigger the
             * onClickOutside handler for the HovercardToggle and the onClose will get invoked.
             */}
            <Form id="date-picker" onSubmit={() => hovercardRef.current?.close()}>
              <Input
                autofocus
                onBlur={() => {
                  // now analyze the input they typed. if invalid, revert it. if valid but after
                  // the end input, assume we're restarting our selection
                  if (!isInputValid(startInputText, validRange, granularity)) {
                    setInputTexts(rangeState.map(formatDate));
                  } else if (endInputText && dayjs(endInputText).isBefore(startInputText)) {
                    resetState([parseDate(startInputText, granularity)]);
                  } else {
                    // let's reformat it the way we want it, regardless of how they typed it in
                    setInputTexts(rangeState.map(formatDate));
                  }
                }}
                onChange={(val) => {
                  // don't set a value higher than the range high end. we'll swap the two if
                  // they are still reversed when the blur the input
                  if (
                    !val ||
                    (isInputValid(val, validRange, granularity) &&
                      parseDate(val, granularity).isBefore(rangeState[1]))
                  ) {
                    // otherwise if valid, edit range state real-time while they type
                    setRangeState(
                      [val ? parseDate(val, granularity) : null].concat(
                        rangeState[1] || [],
                      ) as DateRange,
                    );
                  }

                  setInputTexts([val, endInputText]);
                }}
                placeholder={isMonthly ? "MMM-YYYY" : "MM-DD-YYYY"}
                value={startInputText}
              />
              <span>to</span>
              <Input
                onBlur={() => {
                  // now analyze the input they typed. if it's invalid, revert it.
                  // if valid but before the start input, swap them
                  if (!isInputValid(endInputText, validRange, granularity)) {
                    setInputTexts(rangeState.map(formatDate));
                  } else if (
                    startInputText &&
                    parseDate(endInputText, granularity).isBefore(startInputText)
                  ) {
                    resetState([parseDate(endInputText, granularity), dayjs(startInputText)]);
                  } else {
                    // let's reformat it the way we want it, regardless of how they typed it in
                    setInputTexts(rangeState.map(formatDate));
                  }
                }}
                onChange={(val) => {
                  if (!val) {
                    setRangeState(rangeState.length ? [rangeState[0]] : []);
                    // don't set a value lower than the range low end. we'll swap the two if
                    // they are still reversed when the blur the input
                  } else if (
                    isInputValid(val, validRange, granularity) &&
                    parseDate(val, granularity).isAfter(rangeState[0])
                  ) {
                    // otherwise if valid, edit range state real-time while they type
                    setRangeState([rangeState[0]].concat(parseDate(val, granularity)) as DateRange);
                  }

                  setInputTexts([startInputText, val]);
                }}
                placeholder={isMonthly ? "MMM-YYYY" : "MM-DD-YYYY"}
                value={endInputText}
              />
            </Form>
            <span />
            <Calendar
              granularity={granularity}
              validRange={validRange}
              onSelectRange={resetState}
              selectedRange={rangeState}
            />
            <footer>
              <SubmitButton
                disabled={
                  rangeState.length !== 2 ||
                  isSameRange(dateRange.map(dayjs) as DateRange, rangeState)
                }
                form="date-picker"
                size="small"
              >
                Apply
              </SubmitButton>
            </footer>
          </div>
        )}
        disabled={disabled}
        onClose={() =>
          // this timeout just keeps the closing of the picker a little separation from the updating
          // of any client components. i just think this makes the transition smoother, but up for
          // debate
          window.setTimeout(() => {
            // this is actually the only thing outside the preset picker that calls the
            // onChangeDateRange callback. each action that applies a new range (clicking the Apply
            // button, submitting the form, clicking outside the hovercard) actually just closes the
            // hovercard, so we can group it all here.
            onChangeRange(rangeState);

            // this timeout prevents a flicker between the dropdown reverting its override and new
            // date range state getting applied. We want the override to get removed after any date
            // range updates happen, and the exact ordering of that is fuzzy. So just wait 100ms.
            window.setTimeout(() => setCustomOverride(false), 100);
          }, 100)
        }
      >
        <Button className="calendar-button" icon="calendar" size="small">
          {dateRange.map((day) => formatDate(dayjs(day))).join("\u00a0\u2014\u00a0")}
        </Button>
      </HovercardToggle>
    </div>
  );
}

function generateFormatDate(granularity: PickerProps["granularity"]) {
  const formats = { month: "MMM YYYY", day: "MMM DD, YYYY" };

  return (date: Dayjs | null) => date?.format(formats[granularity]) || "";
}

export function isSameRange(range1: DateRange, range2: DateRange): boolean {
  return range1.every((date, i) => date && date.isSame(range2[i], "day"));
}

/**
 * This is for validating text input. Not only does dayjs need to parse it, but the parsed date
 * must also be within the valid range.
 */
export function isInputValid(
  input: string,
  validRange: ValidRange,
  granularity?: PickerProps["granularity"],
) {
  const inputDay = parseDate(input, granularity);

  return (
    inputDay.isValid() &&
    // the adding and subtracting a day is just a cheap way to not use isSameOrAfter/isSameOrBefore,
    // which requires extending dayjs
    (!validRange[0] || inputDay.isAfter(dayjs(validRange[0]).subtract(1, "day"))) &&
    (!validRange[1] || inputDay.isBefore(dayjs(validRange[1]).add(1, "day")))
  );
}

/**
 * Parsing dates from text is pretty iffy when it's just month and year (no date). So we need to
 * give it some help, by telling it what formats to expect, and also supporting lowercase versions
 * of months.
 */
export function parseDate(text: string = "", granularity: PickerProps["granularity"]): Dayjs {
  return dayjs(capitalizeWords(text), ParseHelpers[granularity]);
}
