import CambioCard from "@/components/CambioCard";
import ChartLabel from "@/components/Charts/ChartLabel";
import Delta from "@/components/Delta";
import MetricValue from "@/components/MetricValue";
import ProgressBar from "@/components/ProgressBar";

import { ChartColors } from "@/js/constants/cambio";
import { classnames } from "@/js/utils/cambio";

const IndustryBenchmarkCard = ({
  currentValue,
  benchmarkValue,
  propertyName,
  unitLabel,
  assetName,
  assetClass,
  sourceName = "Energy Star",
  changePercentage,
  isLoading,
}: {
  currentValue: string;
  assetName: string;
  benchmarkValue: string;
  propertyName: string;
  unitLabel: string;
  assetClass: string;
  sourceName?: string;
  changePercentage: number;
  isLoading: boolean;
}) => {
  const currentValueNumber = parseFloat(currentValue);
  const benchmarkValueNumber = parseFloat(benchmarkValue);
  const maxValue = Math.max(currentValueNumber, benchmarkValueNumber);

  return (
    <CambioCard
      title="Industry Benchmark"
      className="IndustryBenchmarkCard"
      icon="benchmark"
      isLoading={isLoading}
      subtitle={
        <>
          Your property's energy usage intensity is{" "}
          <span className={classnames({ green: changePercentage < 0, red: changePercentage > 0 })}>
            {changePercentage !== 0 ?
              `${Math.abs(changePercentage).toFixed(0)}% ${
                changePercentage < 0 ? "lower" : "higher"
              }`
            : "no different"}
          </span>{" "}
          than the equivalent {assetClass} benchmark period.
        </>
      }
    >
      <section>
        <header>
          <h5>{propertyName}</h5>
        </header>
        <div className="value-content">
          <MetricValue size="large">{currentValue}</MetricValue>
          <ChartLabel>{unitLabel}</ChartLabel>
          {changePercentage && <Delta value={changePercentage} inverted />}
        </div>
        <ProgressBar
          progress={currentValueNumber / maxValue}
          color={ChartColors.TEAL_CAMBIO}
          size="large"
        />
      </section>
      <section>
        <header>
          <h5>
            {sourceName} {assetName} Average 2023
          </h5>
        </header>
        <div className="value-content">
          <MetricValue size="large">
            {/*@ts-ignore */}
            {benchmarkValue && !isNaN(benchmarkValue) ? benchmarkValue : "--"}
          </MetricValue>
          <ChartLabel>{unitLabel}</ChartLabel>
        </div>

        <ProgressBar progress={benchmarkValueNumber / maxValue} color="#BFC5CF" size="large" />
      </section>
    </CambioCard>
  );
};

export default IndustryBenchmarkCard;
