import type { DateRange } from "@/components/Calendar";

import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

import { Granularity } from "../Calendar/types";

dayjs.extend(quarterOfYear);

const TODAY = dayjs().startOf("day");

export enum Presets {
  LAST_TWELVE_MONTHS = "LAST_TWELVE_MONTHS",
  YEAR_TO_DATE = "YEAR_TO_DATE",
  YEAR_TO_DATE_Q1 = "YEAR_TO_DATE_Q1",
  YEAR_TO_DATE_Q2 = "YEAR_TO_DATE_Q2",
  YEAR_TO_DATE_Q3 = "YEAR_TO_DATE_Q3",
  LAST_YEAR = "LAST_YEAR",
  LAST_YEAR_Q1 = "LAST_YEAR_Q1",
  LAST_YEAR_Q2 = "LAST_YEAR_Q2",
  LAST_YEAR_Q3 = "LAST_YEAR_Q3",
  LAST_YEAR_Q4 = "LAST_YEAR_Q4",
  TWO_YEARS_AGO = "TWO_YEARS_AGO",
  TWO_YEARS_AGO_Q1 = "TWO_YEARS_AGO_Q1",
  TWO_YEARS_AGO_Q2 = "TWO_YEARS_AGO_Q2",
  TWO_YEARS_AGO_Q3 = "TWO_YEARS_AGO_Q3",
  TWO_YEARS_AGO_Q4 = "TWO_YEARS_AGO_Q4",
  CUSTOM = "CUSTOM",
}

type PresetOption = {
  display: string;
  value: Presets;
  range: DateRange;
};

type PresetOptionGroup = {
  key: string;
  display: string;
  items: PresetOption[];
};

export const getYearQuarters = (
  baseDate: Dayjs,
  length: number,
  presets: Presets[],
): PresetOption[] =>
  Array.from({ length }, (_, i) => ({
    display: `Q${length - i}${
      baseDate.get("year") !== dayjs().get("year") ? ` \u2018${baseDate.format("YY")}` : ""
    }`,
    value: presets[i],
    range: [
      baseDate.quarter(length - i).startOf("quarter"),
      baseDate.quarter(length - i).endOf("quarter"),
    ],
  }));

/**
 * Our preset options are a little dynamic because:
 *
 * 1. We offer presets for last year and the year before
 * 2. For the current year and the last year, we display quarters, as well. While we'll always show
 *    all four quarters for the previous year, the current year only shows presets for the quarters
 *    up to the current quarter (so there will only ever be max 3).
 */
export const getPresetOptions = (granularity: Granularity): PresetOptionGroup[] => {
  return [
    {
      key: "current-year",
      display: "Current Year",
      items: [
        {
          display: "Year-to-date",
          value: Presets.YEAR_TO_DATE,
          range: [TODAY.startOf("year"), granularity === "month" ? TODAY.endOf("month") : TODAY],
        },
        ...getYearQuarters(
          dayjs(),
          dayjs().quarter() - 1,
          [Presets.YEAR_TO_DATE_Q3, Presets.YEAR_TO_DATE_Q2, Presets.YEAR_TO_DATE_Q1].slice(
            -1 * (dayjs().quarter() - 1),
          ),
        ),
      ],
    },
    {
      key: "prior-years",
      display: "Prior Years",
      items: [
        {
          display: "Last 12 months",
          value: Presets.LAST_TWELVE_MONTHS,
          range:
            granularity === "month" ?
              [
                TODAY.startOf("month").subtract(12, "months"),
                TODAY.subtract(1, "month").endOf("month"),
              ]
            : [TODAY.subtract(12, "months"), TODAY],
        },
        {
          display: dayjs().startOf("year").subtract(1, "year").format("YYYY"),
          value: Presets.LAST_YEAR,
          range: [
            dayjs().startOf("year").subtract(1, "year"),
            dayjs().endOf("year").subtract(1, "year"),
          ],
        },
        ...getYearQuarters(dayjs().subtract(1, "year").endOf("year"), 4, [
          Presets.LAST_YEAR_Q4,
          Presets.LAST_YEAR_Q3,
          Presets.LAST_YEAR_Q2,
          Presets.LAST_YEAR_Q1,
        ]),
        {
          display: dayjs().startOf("year").subtract(2, "year").format("YYYY"),
          value: Presets.TWO_YEARS_AGO,
          range: [
            dayjs().startOf("year").subtract(2, "year"),
            dayjs().endOf("year").subtract(2, "year"),
          ],
        },
      ],
    },
    {
      key: "custom",
      display: "Custom",
      items: [
        {
          display: "Custom range",
          value: Presets.CUSTOM,
          range: [],
        },
      ],
    },
  ];
};
