import type { EnhancedSpace } from "@/Api/generated";
import type { Filter } from "@/components/Filters";

import { useRouter } from "next/router";
import { createContext } from "react";

import { unitFormatter } from "@/js/utils/unitFormatter";

import { Unit57dEnum } from "@/Api/generated";
import { useAppContext } from "@/layouts/AppLayout/AppContext";
import PathToNetZeroCard from "@/pages/PathToNetZeroPage/PathToNetZeroCard";

import PhysicalClimateRiskCard from "../../DrilldownPage/PhysicalClimateRiskCard";
import CertificationsCard from "../../DrilldownPage/ProjectsTab/CertificationsCard";
import AggregatedCertificationsCard from "./AggregatedCertificationsCard";
import AssociatedPropertiesSection from "./AssociatedPropertiesSection";
import CarbonEmissionsCard from "./CarbonEmissionsCard";
import DataCompletenessCard from "./DataCompletenessCard";
import DistrictEnergyCard from "./DistrictEnergyCard";
import ElectricityCard from "./ElectricityCard";
import EnergySourceCard from "./EnergySourceCard";
import EnergyUsageCard from "./EnergyUsageCard";
import NaturalGasCard from "./FuelsCard";
import IntervalDataCard from "./IntervalDataCard";
import MonthlyUtilitySpendCard from "./MonthlyUtilitySpendCard";
import PropertyImageCard from "./PropertyImageCard";
import ScopeBreakdownCard from "./ScopeBreakdownCard";
import SurveyScoreCard from "./SurveyScoreCard";
import { useDashboardResources } from "./utils";
import ValueSpotlight from "./ValueSpotlight";
import WasteBreakdownCard from "./WasteBreakdownCard";
import WaterCard from "./WaterCard";

type DashboardContextValue = {
  property?: EnhancedSpace;
  dateRange: [string, string];
  spaceId?: number;
  filters?: Filter[];
  propertyLoadingStates: { hasLoaded: boolean; isLoading: boolean; hasErrored: boolean };
};

export const DashboardContext = createContext({} as DashboardContextValue);

/**
 * This is used as both the Property Drilldown Overview Tab as well as the aggregate Properties
 * Dashbaord view. It's not a page in and of itself, though.
 */
export default function DashboardContent({
  dateRange,
  filters = [],
}: {
  dateRange: [string, string];
  filters?: Filter[];
}) {
  const { space_token } = useRouter().query;
  const { featureConfigurations } = useAppContext();
  // this will not exist if there is no space_token query param
  const { propertyModel, isLoading, hasErrored, hasLoaded } = useDashboardResources(["property"]);
  const property = propertyModel?.toJSON();

  const {
    energySourceModel,
    energySourceLoadingState,
    energyConsumptionModel,
    energyConsumptionLoadingState,
    totalFootprintModel,
    totalFootprintLoadingState,
  } = useDashboardResources(["totalFootprint", "energySource", "energyConsumption"], {
    dateRange,
    filters,
    /**
     * Use this explicitly instead of relying on the getResources `provides` because that
     * requires an extra render, even if the property model is in the cache. But here we can
     * pass the property directly and not re-store it as state first, which is what useResources
     * would do, causing an extra render and loading state blip.
     */
    spaceId: property?.space_id,
  });

  const dashboardContextValue = {
    property,
    filters,
    dateRange,
    spaceId: property?.space_id,
    propertyLoadingStates: { hasLoaded, isLoading, hasErrored },
  };

  const avgEuiUnit = unitFormatter(energyConsumptionModel.get("energy_unit")) + " psf";

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <div className="DashboardContent">
        <section className="grid-container">
          {space_token ?
            <PropertyImageCard />
          : <PathToNetZeroCard showUpgradeTag filters={filters} />}

          <div className="value-spotlights">
            <ValueSpotlight
              loadingState={totalFootprintLoadingState}
              value={totalFootprintModel
                .get("area")
                ?.toLocaleString("en-US", { maximumFractionDigits: 0 })}
              units="sf"
              label="Total Area"
            />
            <ValueSpotlight
              loadingState={energyConsumptionLoadingState}
              value={
                typeof energyConsumptionModel.get("average_eui") === "number" ?
                  energyConsumptionModel
                    .get("average_eui")
                    .toLocaleString("en-US", { maximumFractionDigits: 1 })
                : null
              }
              units={avgEuiUnit}
              label="Avg Energy Usage Intensity"
              drillDownOption="energy_usage_intensity"
              benchmarkName="avg_eui"
              benchmarks={energyConsumptionModel.get("benchmarks") || undefined}
              dateRange={dateRange}
            />
            <ValueSpotlight
              loadingState={energySourceLoadingState}
              value={
                typeof energySourceModel.get("total_carbon") === "number" ?
                  energySourceModel
                    .get("total_carbon")
                    .toLocaleString("en-US", { maximumFractionDigits: 0 })
                : undefined
              }
              units="metric tons"
              label="Carbon Emissions"
              drillDownOption="carbon_emissions"
              benchmarkName="total_carbon"
              benchmarks={energySourceModel.get("benchmarks") || undefined}
              dateRange={dateRange}
            />
            <ValueSpotlight
              loadingState={energySourceLoadingState}
              value={
                typeof energySourceModel.get("carbon_emission_intensity")?.value === "number" ?
                  energySourceModel
                    .get("carbon_emission_intensity")
                    .value.toLocaleString("en-US", { maximumFractionDigits: 1 })
                : null
              }
              units={energySourceModel.get("carbon_emission_intensity")?.unit}
              label="Carbon Emissions Intensity"
              drillDownOption="carbon_emission_intensity"
              benchmarkName="carbon_emission_intensity"
              benchmarks={energySourceModel.get("benchmarks") || undefined}
              dateRange={dateRange}
            />
            <ValueSpotlight
              loadingState={energyConsumptionLoadingState}
              value={
                typeof energyConsumptionModel.get("total_electricity_consumption") === "number" ?
                  energyConsumptionModel
                    .get("total_electricity_consumption")
                    .toLocaleString("en-US", { maximumFractionDigits: 0 })
                : null
              }
              units={unitFormatter(
                energyConsumptionModel.get("electricity_unit") || Unit57dEnum.KWH,
              )}
              label="Electricity"
            />
            <ValueSpotlight
              loadingState={energyConsumptionLoadingState}
              value={
                typeof energyConsumptionModel.get("total_gas_consumption") === "number" ?
                  energyConsumptionModel
                    .get("total_gas_consumption")
                    .toLocaleString("en-US", { maximumFractionDigits: 0 })
                : null
              }
              units={unitFormatter(
                energyConsumptionModel.get("natural_gas_unit") || Unit57dEnum.THERMS,
              )}
              label="Fuels"
            />
          </div>
          <AssociatedPropertiesSection />
        </section>

        <h2>Carbon</h2>
        <section className="grid-container">
          <CarbonEmissionsCard />
          <ScopeBreakdownCard />
        </section>

        <h2>Energy</h2>
        <section className="grid-container">
          <EnergyUsageCard />
          <EnergySourceCard />
        </section>

        <h2>Climate Resilience</h2>
        <section className="grid-container">
          {featureConfigurations.CAMBIO_SURVEY_ENABLED && <SurveyScoreCard />}
          {space_token ?
            featureConfigurations.CERTIFICATION_AUTOMATION_ENABLED ?
              <CertificationsCard />
            : null
          : featureConfigurations.ORG_LEVEL_AGGREGATE_CERTIFICATIONS_ENABLED ?
            <AggregatedCertificationsCard />
          : null}
          {space_token && featureConfigurations.CLIMATE_RISK_ENABLED && (
            <PhysicalClimateRiskCard property={property} />
          )}
        </section>

        <h2>Utilities</h2>
        <section className="grid-container">
          <ElectricityCard />
          <NaturalGasCard />
          {featureConfigurations.ORG_LEVEL_DISTRICT_STEAM_ENABLED && <DistrictEnergyCard />}
          {featureConfigurations.WATER_WASTE_ORG_LEVEL_ENABLED && <WaterCard />}
          {featureConfigurations.ORG_LEVEL_WASTE_ENABLED ?
            <WasteBreakdownCard />
          : null}
          <MonthlyUtilitySpendCard />
          {space_token && featureConfigurations.PROPERTY_LEVEL_INTERVAL_DATA_ENABLED ?
            <IntervalDataCard />
          : null}
        </section>

        <h2>Asset Management</h2>
        <section className="grid-container">
          <DataCompletenessCard />
        </section>
      </div>
    </DashboardContext.Provider>
  );
}
