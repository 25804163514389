export default {
  expected_path: [
    { year: 2022, value: 2303.6 },
    { year: 2023, value: 2221.3285714285716 },
    { year: 2024, value: 2139.057142857143 },
    { year: 2025, value: 2056.785714285714 },
    { year: 2026, value: 1974.5142857142857 },
    { year: 2027, value: 1892.2428571428572 },
    { year: 2028, value: 1809.9714285714285 },
    { year: 2029, value: 1727.7 },
    { year: 2030, value: 1645.4285714285713 },
    { year: 2031, value: 1563.1571428571428 },
    { year: 2032, value: 1480.8857142857144 },
    { year: 2033, value: 1398.6142857142856 },
    { year: 2034, value: 1316.3428571428572 },
    { year: 2035, value: 1234.0714285714287 },
    { year: 2036, value: 1151.8 },
    { year: 2037, value: 1069.5285714285715 },
    { year: 2038, value: 987.2571428571429 },
    { year: 2039, value: 904.9857142857143 },
    { year: 2040, value: 822.7142857142857 },
    { year: 2041, value: 740.4428571428572 },
    { year: 2042, value: 658.1714285714286 },
    { year: 2043, value: 575.9 },
    { year: 2044, value: 493.62857142857143 },
    { year: 2045, value: 411.35714285714283 },
    { year: 2046, value: 329.0857142857143 },
    { year: 2047, value: 246.81428571428572 },
    { year: 2048, value: 164.54285714285714 },
    { year: 2049, value: 82.27142857142857 },
    { year: 2050, value: 0 },
  ],
  grid_decarb_path: [
    { year: 2022, value: 2303.6 },
    { year: 2023, value: 2303.6 },
    { year: 2024, value: 2303.6 },
    { year: 2025, value: 1432.3328580159462 },
    { year: 2026, value: 1316.1203915483031 },
    { year: 2027, value: 1316.1203915483031 },
    { year: 2028, value: 1098.2200053746428 },
    { year: 2029, value: 1098.2200053746428 },
    { year: 2030, value: 1002.0277636160336 },
    { year: 2031, value: 1002.0277636160336 },
    { year: 2032, value: 1002.0277636160336 },
    { year: 2033, value: 1002.0277636160336 },
    { year: 2034, value: 1002.0277636160336 },
    { year: 2035, value: 940.2673233837312 },
    { year: 2036, value: 940.2673233837312 },
    { year: 2037, value: 940.2673233837312 },
    { year: 2038, value: 940.2673233837312 },
    { year: 2039, value: 940.2673233837312 },
    { year: 2040, value: 897.9298886277568 },
    { year: 2041, value: 897.9298886277568 },
    { year: 2042, value: 897.9298886277568 },
    { year: 2043, value: 897.9298886277568 },
    { year: 2044, value: 897.9298886277568 },
    { year: 2045, value: 837.017712321724 },
    { year: 2046, value: 837.017712321724 },
    { year: 2047, value: 837.017712321724 },
    { year: 2048, value: 837.017712321724 },
    { year: 2049, value: 837.017712321724 },
    { year: 2050, value: 787.2076365194572 },
  ],
  retrofit_complete_path: [
    { year: 2022, value: 2303.6 },
    { year: 2023, value: 2303.6 },
    { year: 2024, value: 2243.6 },
    { year: 2025, value: 1372.3328580159462 },
    { year: 2026, value: 1256.1203915483031 },
    { year: 2027, value: 1256.1203915483031 },
    { year: 2028, value: 1038.2200053746428 },
    { year: 2029, value: 1038.2200053746428 },
    { year: 2030, value: 942.0277636160336 },
    { year: 2031, value: 942.0277636160336 },
    { year: 2032, value: 942.0277636160336 },
    { year: 2033, value: 942.0277636160336 },
    { year: 2034, value: 942.0277636160336 },
    { year: 2035, value: 880.2673233837312 },
    { year: 2036, value: 880.2673233837312 },
    { year: 2037, value: 880.2673233837312 },
    { year: 2038, value: 880.2673233837312 },
    { year: 2039, value: 880.2673233837312 },
    { year: 2040, value: 837.9298886277568 },
    { year: 2041, value: 837.9298886277568 },
    { year: 2042, value: 837.9298886277568 },
    { year: 2043, value: 837.9298886277568 },
    { year: 2044, value: 837.9298886277568 },
    { year: 2045, value: 777.017712321724 },
    { year: 2046, value: 777.017712321724 },
    { year: 2047, value: 777.017712321724 },
    { year: 2048, value: 777.017712321724 },
    { year: 2049, value: 777.017712321724 },
    { year: 2050, value: 727.2076365194572 },
  ],
  retrofit_in_progress_path: [
    { year: 2022, value: 2303.6 },
    { year: 2023, value: 2303.6 },
    { year: 2024, value: 2235.6 },
    { year: 2025, value: 1364.3328580159462 },
    { year: 2026, value: 1248.1203915483031 },
    { year: 2027, value: 1248.1203915483031 },
    { year: 2028, value: 1030.2200053746428 },
    { year: 2029, value: 1030.2200053746428 },
    { year: 2030, value: 934.0277636160336 },
    { year: 2031, value: 934.0277636160336 },
    { year: 2032, value: 934.0277636160336 },
    { year: 2033, value: 934.0277636160336 },
    { year: 2034, value: 934.0277636160336 },
    { year: 2035, value: 872.2673233837312 },
    { year: 2036, value: 872.2673233837312 },
    { year: 2037, value: 872.2673233837312 },
    { year: 2038, value: 872.2673233837312 },
    { year: 2039, value: 872.2673233837312 },
    { year: 2040, value: 829.9298886277568 },
    { year: 2041, value: 829.9298886277568 },
    { year: 2042, value: 829.9298886277568 },
    { year: 2043, value: 829.9298886277568 },
    { year: 2044, value: 829.9298886277568 },
    { year: 2045, value: 769.017712321724 },
    { year: 2046, value: 769.017712321724 },
    { year: 2047, value: 769.017712321724 },
    { year: 2048, value: 769.017712321724 },
    { year: 2049, value: 769.017712321724 },
    { year: 2050, value: 719.2076365194572 },
  ],
  retrofit_budget_path: [
    { year: 2022, value: 2303.6 },
    { year: 2023, value: 2303.6 },
    { year: 2024, value: 2232.6 },
    { year: 2025, value: 1121.3328580159462 },
    { year: 2026, value: 1005.1203915483031 },
    { year: 2027, value: 995.1203915483031 },
    { year: 2028, value: 777.2200053746428 },
    { year: 2029, value: 777.2200053746428 },
    { year: 2030, value: 681.0277636160336 },
    { year: 2031, value: 681.0277636160336 },
    { year: 2032, value: 681.0277636160336 },
    { year: 2033, value: 681.0277636160336 },
    { year: 2034, value: 681.0277636160336 },
    { year: 2035, value: 619.2673233837312 },
    { year: 2036, value: 619.2673233837312 },
    { year: 2037, value: 619.2673233837312 },
    { year: 2038, value: 619.2673233837312 },
    { year: 2039, value: 619.2673233837312 },
    { year: 2040, value: 576.9298886277568 },
    { year: 2041, value: 576.9298886277568 },
    { year: 2042, value: 576.9298886277568 },
    { year: 2043, value: 576.9298886277568 },
    { year: 2044, value: 576.9298886277568 },
    { year: 2045, value: 516.017712321724 },
    { year: 2046, value: 516.017712321724 },
    { year: 2047, value: 516.017712321724 },
    { year: 2048, value: 516.017712321724 },
    { year: 2049, value: 516.017712321724 },
    { year: 2050, value: 466.2076365194572 },
  ],
  baseline_emission: 2303.6,
  baseline_year: 2022,
  unit: "metric tons of carbon",
  total_mitigation_emission: 0,
};
