import type { ExecutorFunction } from "resourcerer";

import { memo, MutableRefObject, useEffect } from "react";
import { useResources } from "resourcerer";

import CambioCard from "@/components/CambioCard";
import LineChart, { LineChartDataField } from "@/components/Charts/LineChart";
import { Filter, FilterTypes } from "@/components/Filters";
import Tag from "@/components/Tag";

import { ChartColors } from "@/js/constants/cambio";
import dummyEmissionReductionData from "@/js/constants/dummyData/emissionReduction";

import { useAppContext } from "@/layouts/AppLayout/AppContext";
import { getFiltersQueryParams } from "@/pages/DashboardPage/DashboardContent/utils";

import { transformData } from "./util";

const getResources: ExecutorFunction<
  "emissionReduction",
  {
    orgToken: string;
    locked?: boolean;
    filters: Record<FilterTypes, string>;
  }
> = ({ orgToken, ...props }) => ({
  emissionReduction: { params: props.filters, dependsOn: !props.locked, path: { orgToken } },
});

// TODO: these should go in a constants file somewhere
export const ChartGrays = {
  DARK: "#6b7688",
  LIGHT: "#e7eaef",
};

export default memo(function PathToNetZeroCard({
  onPledgeUpdateRef,
  filters,
  showUpgradeTag,
}: {
  /**
   * Ref passed down to attach the refetch function to so that the TargetSettingsCard can call
   * it after a pledge is updated. This will only exist on the PTNZ page.
   */
  onPledgeUpdateRef?: MutableRefObject<() => void>;
  filters?: Filter[];
  /** As a card, we want to show the upgrade tag and gray out the chart. On the PTNZ page, we don't */
  showUpgradeTag?: boolean;
}) {
  const { featureConfigurations, organizationToken, isInPortfolioContext, organizationName } =
    useAppContext();
  const locked = isInPortfolioContext || !featureConfigurations.PATH_TO_NET_ZERO_ORG_LEVEL_ENABLED;

  const { hasInitiallyLoaded, isLoading, hasErrored, refetch, emissionReductionModel } =
    useResources(getResources, {
      locked,
      orgToken: organizationToken,
      filters: getFiltersQueryParams(filters),
    });

  const data = locked ? dummyEmissionReductionData : emissionReductionModel?.toJSON();

  const grayOutChart = locked && showUpgradeTag;

  /**
   * This refetch attached to a ref is a way to keep the refetch and data loading in this component
   * instead of needing to lift it up so that the TargetSettingsCard can access it.
   */
  useEffect(() => {
    if (onPledgeUpdateRef) {
      onPledgeUpdateRef.current = () => refetch("emissionReduction");
    }
  }, []);

  return (
    <CambioCard
      className="PathToNetZeroCard"
      title={"Path to Net Zero for " + organizationName}
      label="metric tons"
      locked={grayOutChart && !featureConfigurations.PATH_TO_NET_ZERO_ORG_LEVEL_ENABLED}
      hasErrored={hasErrored}
      isLoading={isLoading}
      // these next two props are a custom cases for PTNZ card. we want to show a Coming Soon tag
      // on TPV dashboard that resembles the upgrade tag
      actionBar={isInPortfolioContext ? <Tag color="blue" strong text="Coming soon" /> : null}
      disabled={isInPortfolioContext && featureConfigurations.PATH_TO_NET_ZERO_ORG_LEVEL_ENABLED}
    >
      {locked || hasInitiallyLoaded ?
        <LineChart
          data={transformData(data)}
          xAxisKey="year"
          yAxisChartDataFields={
            [
              {
                key: "expected_path",
                color: ChartColors.TEAL_DEEP,
                name: "Target Pathway",
                iconShape: "circle-outline-dashed",
                lineType: "dashed",
              },
              {
                key: "grid_decarb_path",
                color: ChartColors.TEAL_DARK,
                name: "Grid Decarbonization",
              },
              {
                key: "retrofit_complete_path",
                color: ChartColors.TEAL_CAMBIO,
                name: "Completed Interventions",
              },
              {
                key: "retrofit_in_progress_path",
                color: ChartColors.LIME,
                name: "Completed & In Progress Interventions",
              },
              {
                key: "retrofit_budget_path",
                color: ChartColors.LEMON,
                name: "Completed, In Progress & Budgeted Interventions",
              },
            ].map((field) =>
              grayOutChart ?
                {
                  ...field,
                  color: field.key === "expected_path" ? ChartGrays.DARK : ChartGrays.LIGHT,
                }
              : field,
            ) as LineChartDataField[]
          }
          tooltipLabelKey="year"
          hasLegend
          lineWidth="small"
          referenceDots={[
            {
              x: data.baseline_year,
              y: data.baseline_emission,
              color: grayOutChart ? ChartGrays.DARK : ChartColors.TEAL_DEEP,
              shape: "dot",
              name: "Baseline Performance",
            },
          ]}
          tooltipValueFormatter={(value) =>
            value.toLocaleString("en-US", { maximumFractionDigits: 1 })
          }
        />
      : null}
    </CambioCard>
  );
});
