import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import { memo } from "react";

import { CardDownloadButton } from "@/components/Button/CardDownloadButton";
import CambioCard from "@/components/CambioCard";
import AreaChart from "@/components/Charts/AreaChart";
import BarChart from "@/components/Charts/BarChart";
import ChartKpiTitle from "@/components/Charts/ChartKpiTitle";
import MetricValue from "@/components/MetricValue";

import { ChartColors } from "@/js/constants/cambio";
import { convertToCsvAndDownload, generateCSVTitle } from "@/js/utils/csvCreation";
import { formatNumber, formatPercentage } from "@/js/utils/stringFormatter";
import { processUnitValue, unitFormatter } from "@/js/utils/unitFormatter";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getSelectedYear, useDashboardContext, useDashboardResources } from "../utils";

type WasteBreakdownFields = "disposed" | "donated_reused" | "recycled" | "composted";

const WasteBreakdownConfig: Record<
  WasteBreakdownFields,
  { key: WasteBreakdownFields; name: string; color: string }
> = {
  disposed: { key: "disposed", name: "Disposed", color: ChartColors.TEAL_DARK },
  donated_reused: {
    key: "donated_reused",
    name: "Donated / Reused",
    color: ChartColors.TEAL_CAMBIO,
  },
  recycled: { key: "recycled", name: "Recycled", color: ChartColors.LIME },
  composted: { key: "composted", name: "Composted", color: ChartColors.LEMON },
};

/**
 * Shows an AreaChart for the breakdown of waste content. There are four possible waste buckets,
 * but not all properties will have all of them all the time. We only show the buckets that are
 * present.
 */
export default memo(function WasteBreakdownCard() {
  const { featureConfigurations, organizationName } = useAppContext();
  const { property, dateRange } = useDashboardContext();
  const useAnnualizedData = !!featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED;
  const filterSelectedYear = ({ date }: { date: string }) =>
    !useAnnualizedData || getSelectedYear(dateRange) === date;

  const { meterBreakdownModel, isLoading, hasErrored, hasInitiallyLoaded } = useDashboardResources(
    ["meterBreakdown"],
    { useAnnualizedData },
  );
  const wasteData: {
    date: string;
    data: {
      subtype: WasteBreakdownFields;
      value: number;
    }[];
  }[] =
    useAnnualizedData ?
      meterBreakdownModel.get("annual_waste") || []
    : meterBreakdownModel.get("monthly_waste") || [];
  const unit = unitFormatter(meterBreakdownModel.get("waste_unit"));

  // which of the four waste fields do we have in this chart?
  const fieldSet = sortBy(
    [...new Set(wasteData.flatMap(({ data }) => data.map(({ subtype }) => subtype)))],
    (field) => Object.keys(WasteBreakdownConfig).indexOf(field),
  );

  const chartData = wasteData.map((entry) => ({
    date: entry.date,
    // the area chart handles this for its tooltip, but we also need it for the CSV download
    total: sumBy(entry.data, "value"),
    ...entry.data.reduce(
      (memo, { subtype, value }) => Object.assign(memo, { [subtype]: value }),
      {},
    ),
  }));

  const totalWaste = sumBy(chartData.filter(filterSelectedYear), "total");
  const divertedWaste = sumBy(
    wasteData.filter(filterSelectedYear).flatMap(({ data }) =>
      sumBy(
        data.filter(({ subtype }) => subtype !== "disposed"),
        "value",
      ),
    ),
  );

  return (
    <CambioCard
      title="Waste Breakdown"
      className="WasteBreakdownCard"
      label={hasInitiallyLoaded ? unit : "\xa0"}
      hasErrored={hasErrored}
      isLoading={isLoading}
      actionBar={
        <CardDownloadButton
          name="Waste"
          onClick={() => {
            convertToCsvAndDownload(
              [
                { title: "End Date", key: "date" },
                ...[
                  { title: "Total", key: "total" },
                  { title: "Disposed", key: "disposed" },
                  { title: "Donated/Reused", key: "donated_reused" },
                  { title: "Recycled", key: "recycled" },
                  { title: "Composted", key: "composted" },
                ].map((entry) => ({
                  ...entry,
                  processValue: (value: number) => formatNumber(value || 0, 2),
                })),
                {
                  title: "Unit of Measurement",
                  value: unit || "\xa0",
                  processValue: processUnitValue,
                },
              ],
              chartData,
              generateCSVTitle(
                "Waste Breakdown",
                property ? property.name : organizationName,
                dateRange,
              ),
            );
          }}
        />
      }
    >
      {hasInitiallyLoaded ?
        <>
          <div className="title-container">
            <MetricValue size="large">
              {formatPercentage(totalWaste ? (divertedWaste * 100) / totalWaste : 0, 0)}
            </MetricValue>
            <ChartKpiTitle>Waste Diverted</ChartKpiTitle>
          </div>
          {useAnnualizedData ?
            <BarChart
              data={chartData}
              xAxisKey="date"
              yAxisChartDataFields={fieldSet
                .map((field, i) => WasteBreakdownConfig[field])
                .map((entry) => ({
                  ...entry,
                  style: (entry) =>
                    entry.date !== getSelectedYear(dateRange) ? { opacity: 0.3 } : {},
                }))}
              tooltipValueFormatter={(value) => formatNumber(value || 0, 0)}
              hasReferenceLine={false}
            />
          : <AreaChart
              data={chartData}
              xAxisKey="date"
              yAxisChartDataFields={fieldSet.map((field, i) => WasteBreakdownConfig[field])}
              tooltipValueFormatter={(value) => formatNumber(value || 0, 0)}
              hasReferenceLine={false}
            />
          }
        </>
      : null}
    </CambioCard>
  );
});
